const axios = require('axios');
const TOKEN = `https://hooks.slack.com/services/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`;

axios.defaults.headers.post['Content-Type'] = 'application/json';

export default function sendMessage(message) {
	var data = {
        text: message,
        username: 'NKS',
        channel: '#nks'
	};
	axios.post(TOKEN, JSON.stringify(data), {
        withCredentials: false,
        transformRequest: [(data, headers) => {
          delete headers.post["Content-Type"]
          return data
        }]
    })
}