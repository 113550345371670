import React, { PureComponent } from "react"
import PropTypes from 'prop-types';
import './Button.scss'

class Button extends PureComponent {
    render() {
        const { text, onClick, className } = this.props;
        return (
            <button className={ "ButtonItem " + ((className) ? className : '') } onClick={onClick}>{ text }</button>
        )
    }
}

Button.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
}

export default Button