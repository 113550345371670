import React, { PureComponent } from "react"
import PropTypes from 'prop-types';
import BoxRenderer from '../BoxRenderer'
import './Privacy.scss'
import locales from '../../constants/locales'

class Privacy extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPrivacy: false
        }
    }

    componentDidMount() {
        if(!localStorage.getItem('privacy')) {
            this.setState({
                showPrivacy: true
            })
        }
    }

    onClick = () => {
        localStorage.setItem('privacy', 'accepted')
        this.setState({
            showPrivacy: false
        })
    }

    render() {
        const { locale } = this.props;
        const { showPrivacy } = this.state;
        const { PRIVACY_DIALOG, PRIVACY_DIALOG_CONFIRM } = locales[locale].textEntries;
        return (showPrivacy) ? (
            <div className="DialogPrivacy">
                <BoxRenderer>
                    <div dangerouslySetInnerHTML={{
                        __html: PRIVACY_DIALOG
                    }}></div>
                    <div className="ButtonConfirm" onClick={this.onClick}>{ PRIVACY_DIALOG_CONFIRM }</div>
                </BoxRenderer>
            </div> 
        ): <div></div>
    }
}

Privacy.propTypes = {
    locale: PropTypes.string
}

export default Privacy