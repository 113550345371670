import React from "react"
import { Element } from "react-scroll"
import { navigate } from "gatsby"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import Img from "gatsby-image"
import BoxExperience from "../BoxExperience"

const ProjectList = ({ projects, locale, show }) => {
  const { PROJECTS, MYPROJECTS, VISIT_SITE } = locale
  return (
    <>
      <Element name={PROJECTS}>
        <h4 className="TitleText ProjectsTitle">{MYPROJECTS}</h4>
      </Element>
      <div className="ProjectsContainer">
        {projects.map(
          ({
            key,
            years,
            position,
            title,
            text,
            url,
            img,
            animated,
            button,
          }) => (
            <BoxExperience
              key={key}
              years={years}
              position={position}
              title={title}
              className={`${show ? "show" : ""}`}
              text={remark()
                .use(recommended)
                .use(remarkHtml)
                .processSync(text)
                .toString()}
              moreInformations={url ? (button ? button : VISIT_SITE) : null}
              onClick={() => {
                if (!url) return null
                if (button) return navigate(url)
                return window.open(url, "_blank")
              }}
            >
              <div
                onClick={() => {
                  if (!url) return null
                  if (button) return navigate(url)
                  return window.open(url, "_blank")
                }}
              >
                <Img
                  fluid={img}
                  alt={title}
                  title={title}
                  className={"ImageProject " + (animated ? "animated" : "")}
                />
              </div>
            </BoxExperience>
          )
        )}
      </div>
    </>
  )
}

ProjectList.defaultProps = {
  show: true,
}

export default ProjectList
