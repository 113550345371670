import React, { PureComponent } from "react"
import './BoxContact.scss'
import { Link } from 'gatsby'
import BoxRenderer from '../BoxRenderer'
import locales from '../../constants/locales'
import sendMessage from '../../constants/slack'
import Logo from '../../assets/images/logo.png'

class BoxContact extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            statusSend: false
        }
    }

    onChangeEvent = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    sendMessage = () => {
        const { name, email, subject, message } = this.state
        const { lang } = this.props
        const {
            CONTACT_ERROR_NAME,
            CONTACT_ERROR_EMAIL,
            CONTACT_ERROR_SUBJECT,
            CONTACT_ERROR_MESSAGE,
            CONTACT_SUCCESS_MESSAGE
        } = locales[lang].textEntries
        
        if(name.length === 0) {
            this.setState({
                statusSend: {
                    status: false,
                    message: CONTACT_ERROR_NAME
                }
            })
            return;
        }
        if(email.length === 0 || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
            this.setState({
                statusSend: {
                    status: false,
                    message: CONTACT_ERROR_EMAIL
                }
            })
            return;
        }
        if(subject.length === 0) {
            this.setState({
                statusSend: {
                    status: false,
                    message: CONTACT_ERROR_SUBJECT
                }
            })
            return;
        }
        if(message.length === 0) {
            this.setState({
                statusSend: {
                    status: false,
                    message: CONTACT_ERROR_MESSAGE
                }
            })
            return;
        }

        sendMessage(`>>>
            *TI È ARRIVATO UN NUOVO MESSAGGIO*\n\n
            ${name}\n
            _${email}_\n
            *${subject}*\n\n
            ${message}\n\n
            ---\n
            _${new Date()}_
        `)

        this.setState({
            statusSend: {
                status: true,
                message: CONTACT_SUCCESS_MESSAGE
            }
        })
        return;
    }

    render() {
        const { className, lang } = this.props;

        let privacyUrl = '/privacy';
        let pageNotFoundUrl = '/404';
        if(lang === 'it') {
            privacyUrl = '/it/privacy';
            pageNotFoundUrl = '/it/404';
        } 

        const {
            CONTACT_TITLE,
            CONTACT_NAME,
            CONTACT_EMAIL,
            CONTACT_SUBJECT,
            CONTACT_MESSAGE,
            CONTACT_SEND,
            CONTACT_PHONE,
            CONTACT_LINKEDIN,
            CONTACT_LINKEDIN_LINK,
            CONTACT_404_PAGE,
            CONTACT_GITHUB_PAGE,
            DESCRIPTION,
            LINKEDIN,
            CONTACT_SITE_INFORMATION,
            CONTACT_SUCCESS_MESSAGE
        } = locales[lang].textEntries

        const { name, email, subject, message, statusSend } = this.state;
        return (
            <div className="BoxContactContainer">
                <BoxRenderer className={ "BoxContactForm " + className }>
                    <h5>{ CONTACT_TITLE }</h5>
                    <input type="text" name="name" placeholder={ CONTACT_NAME } value={name} onChange={this.onChangeEvent}/>
                    <input type="text" name="email" placeholder={ CONTACT_EMAIL } value={email} onChange={this.onChangeEvent}/>
                    <input type="text" name="subject" placeholder={ CONTACT_SUBJECT } value={subject} onChange={this.onChangeEvent}/>
                    <textarea type="text" name="message" placeholder={ CONTACT_MESSAGE } value={message} onChange={this.onChangeEvent}></textarea>
                    <button onClick={this.sendMessage}>{ CONTACT_SEND }</button>
                    { statusSend ? (
                        <div className="ContactMessage">
                            { statusSend.status ? (
                                <p className="Success">{ CONTACT_SUCCESS_MESSAGE }</p>
                            ) : (
                                <p className="Error">{ statusSend.message }</p>
                            )}
                        </div>
                    ) : (
                        <></>
                    ) }
                </BoxRenderer>
                <BoxRenderer className={ "BoxContactMaps " + className }>
                    <div>
                        <div className="row">
                            <div className="field">{ CONTACT_PHONE }</div>
                            <div className="value">+39 320 463 2299</div>
                        </div>
                        <div className="row">
                            <div className="field">EMAIL</div>
                            <div className="value">mosca.nikolas@gmail.com</div>
                        </div>
                        <div className="row">
                            <div className="field">P.IVA</div>
                            <div className="value">02620400024</div>
                        </div>
                        <div className="row">
                            <div className="field">{ CONTACT_LINKEDIN }</div>
                            <div className="value">
                                <a 
                                    href="https://www.linkedin.com/in/nikolas-mosca-frontend-backend-web-developer" 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    alt={ DESCRIPTION }
                                    title={ LINKEDIN }
                                >
                                    { CONTACT_LINKEDIN_LINK }
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">Privacy Policy</div>
                            <div className="value">
                                <Link to={privacyUrl}>{ CONTACT_LINKEDIN_LINK }</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">{ CONTACT_404_PAGE }</div>
                            <div className="value">
                                <Link to={pageNotFoundUrl}>{ CONTACT_LINKEDIN_LINK }</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">{ CONTACT_GITHUB_PAGE }</div>
                            <div className="value">
                                <a 
                                    href="https://github.com/NikolasMosca" 
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    { CONTACT_LINKEDIN_LINK }
                                </a>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="information">
                                <img src={Logo} alt="Nikolas Mosca Fullstack Web Developer"/>
                                { CONTACT_SITE_INFORMATION }
                            </div>
                        </div>
                    </div>
                </BoxRenderer>
            </div>
        )
    }
}

export default BoxContact