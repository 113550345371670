import React, { PureComponent } from "react"
import PropTypes from 'prop-types';
import './BoxExperience.scss'
import BoxRenderer from '../BoxRenderer'
import Button from '../Button'

class BoxExperience extends PureComponent {
    render() {
        const { years, title, position, text, moreInformations, className, onClick, children } = this.props;
        return (
            <BoxRenderer className={"BoxExperience " + ((className) ? className : '')}>
                <div>
                    <h5 className="Years">{years}</h5>
                    <h5 className={ "Title" + ((onClick) ? ' clickable' : '') } onClick={() => {
                        (onClick) ? onClick() : console.log('clicked')
                    }}>{title}</h5>
                    { children }
                    <h5 className="Position">{position}</h5>     
                    <h5 className="Text" dangerouslySetInnerHTML={{ __html: text }}></h5> 
                </div>

                {moreInformations && (
                    <div className="ButtonContainer">
                        <Button text={ moreInformations } onClick={() => {
                            (onClick) ? onClick() : console.log('clicked')
                        }}/>
                    </div>
                )}
            </BoxRenderer>
        )
    }
}

BoxExperience.propTypes = {
    years: PropTypes.string,
    title: PropTypes.string,
    position: PropTypes.string,
    text: PropTypes.string,
    moreInformations: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.any
}

export default BoxExperience